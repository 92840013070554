@import 'variables.scss';

.text-mutted {
    color: gray;
    font-size: 13px;
}

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiFormControlLabel-label {
    width: 100%;
}
